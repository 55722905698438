<template>
  <div>
    <div class="card mt-2 shadow-sm">
      <div class="card-header border-0">
        <b-form-checkbox> 一般宅配(2) </b-form-checkbox>
      </div>
      <div class="products">
        <div
          class="products__item p-2 py-3"
          v-for="p in products"
          :key="p.name"
        >
          <div class="products__item__check ml-2">
            <b-form-checkbox />
          </div>
          <div class="products__item__photo product__photo">
            <img
              :src="`https://picsum.photos/200?${Math.random()}`"
              class="img-fluid"
            />
          </div>
          <div
            class="
              products__item__title
              product__title
              d-inline-block
              text-truncate
            "
          >
            {{ p.title }}
          </div>
          <div class="products__item__price">
            <span class="oprice mr-1">{{ p.oprice }}</span>
            <span class="price">{{ p.price }}</span>
          </div>
          <div class="products__item__quantity">
            <b-form-spinbutton
              size="sm"
              inline
              v-model="p.quantity"
              min="1"
              class="mt-2"
            ></b-form-spinbutton>
          </div>
          <div class="products__item__promo mt-3 d-flex align-items-center">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="8" cy="8" r="8" fill="#FF1744" />
              <path
                d="M7.30511 3.3051C7.50046 3.10975 7.76542 3 8.04168 3C8.31795 3 8.5829 3.10975 8.77825 3.3051C8.9736 3.50045 9.08335 3.7654 9.08335 4.04167V8.20833C9.08335 8.4846 8.9736 8.74955 8.77825 8.9449C8.5829 9.14025 8.31795 9.25 8.04168 9.25C7.76542 9.25 7.50046 9.14025 7.30511 8.9449C7.10976 8.74955 7.00002 8.4846 7.00002 8.20833V4.04167C7.00002 3.7654 7.10976 3.50045 7.30511 3.3051Z"
                fill="white"
              />
              <path
                d="M8.90627 11.7917C8.94569 11.8497 8.97722 11.9128 9.00002 11.9792C9.0373 12.037 9.06542 12.1002 9.08335 12.1667C9.08846 12.236 9.08846 12.3056 9.08335 12.375C9.08171 12.5111 9.05339 12.6456 9.00002 12.7708C8.95323 12.9001 8.87859 13.0175 8.78139 13.1147C8.68418 13.2119 8.56678 13.2866 8.43752 13.3333C8.31283 13.3884 8.17801 13.4169 8.04168 13.4169C7.90536 13.4169 7.77054 13.3884 7.64585 13.3333C7.51658 13.2866 7.39919 13.2119 7.30198 13.1147C7.20477 13.0175 7.13013 12.9001 7.08335 12.7708C7.02997 12.6456 7.00166 12.5111 7.00002 12.375C6.99922 12.2379 7.0255 12.102 7.07734 11.9751C7.12918 11.8482 7.20556 11.7328 7.3021 11.6354C7.40335 11.5434 7.51962 11.4694 7.64585 11.4167C7.83555 11.3369 8.04463 11.3151 8.2467 11.354C8.44877 11.393 8.63478 11.4909 8.78127 11.6354L8.90627 11.7917Z"
                fill="white"
              />
            </svg>
            <span class="text-danger mx-1"> 未符合 </span>

            <span class="table-danger px-2 py-1 rounded rounded-1"
              >咖啡周邊買2件打8折</span
            >
          </div>
        </div>
      </div>
    </div>

    <div class="card mt-2 d-block shadow-sm">
      <div class="card-header border-0">加價購</div>

      <swiper
        :options="{
          slidesPerView: 'auto',
          pagination: { el: '.swiper-pagination', clickable: true },
        }"
        class="product-carousel"
      >
        <swiper-slide
          class="product-carousel__item p-2 py-3 d-flex flex-column"
          v-for="p in additions"
          :key="p.name"
        >
          <div class="product__photo">
            <img
              :src="`https://picsum.photos/200?${Math.random()}`"
              class="img-fluid"
            />
          </div>

          <div class="product-carousel__item__title product__title">
            {{ p.title }}
          </div>
          <div
            class="
            product-carousel__item__price
              flex-fill
              d-flex
              flex-column
              justify-content-end
            "
          >
            <div class="oprice mr-1">{{ p.oprice }}</div>
            <div class="price">{{ p.price }}</div>
          </div>
          <button class="p-2 btn product-carousel__item__purchase mt-2">加購</button>
        </swiper-slide>

        <div slot="pagination" class="swiper-pagination"></div>
      </swiper>
    </div>

    <div class="card mt-2 d-block shadow-sm">
      <div class="card-header border-0">結帳明細</div>

      <div class="p-3 detail">
        <div class="d-flex justify-content-between">
          <span>商品總金額</span>
          <span class="text-dark">$ 4,160</span>
        </div>

        <div class="d-flex justify-content-between">
          <span>活動折抵</span>
          <span class="text-dark">– $ 600</span>
        </div>
      </div>

      <div class="d-flex justify-content-between card-header border-0">
        <span>總金額</span>
        <span class="price">NT$ 3,560</span>
      </div>
    </div>

    <div class="sticky-bottom d-flex justify-content-between align-items-center p-3">
      <div>
        <div class="font-weight-bold">
          總金額<span class="price">$ 3,560</span>
        </div>
        <div class="small">
        <span class="mr-2">總額 $4,160</span><span>折抵 $600</span>
      </div>
      </div>

      <div class="flex-fill pl-3">
        <router-link class="btn w-100 btn-primary btn-lg" :to="{ name: 'LiffShopCheckout' }">
          結帳(2)
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import themeColor from "@/mixins/liff/themeColor";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

// install Swiper modules

import "swiper/css/swiper.css";

const products = [
  {
    title: "Eco-Friendly Shopping Bag | CHAMOMILE - SIZE S",
    brand: "yaambrand",
    price: "NT$ 375",
    quantity: 1,
  },
  {
    title: "Rex│銀色反光偏光鏡片│墨鏡│太陽眼鏡",
    brand: "墨鏡人生",
    oprice: "NT$ 1,980",
    price: "NT$ 375",
    quantity: 1,
  },
];

const additions = [
  {
    title: "Eco-Friendly Shopping Bag | CHAMOMILE - SIZE S",
    brand: "yaambrand",
    price: "NT$ 375",
  },
  {
    title: "Rex│銀色反光偏光鏡片│墨鏡│太陽眼鏡",
    brand: "墨鏡人生",
    oprice: "NT$ 1,980",
    price: "NT$ 375",
  },
  {
    title: "Eco-Friendly Shopping Bag | CHAMOMILE - SIZE S",
    brand: "yaambrand",
    price: "NT$ 375",
  },
  {
    title: "Rex│銀色反光偏光鏡片│墨鏡│太陽眼鏡",
    brand: "墨鏡人生",
    oprice: "NT$ 1,980",
    price: "NT$ 375",
  },
  {
    title: "Eco-Friendly Shopping Bag | CHAMOMILE - SIZE S",
    brand: "yaambrand",
    price: "NT$ 375",
  },
  {
    title: "Rex│銀色反光偏光鏡片│墨鏡│太陽眼鏡",
    brand: "墨鏡人生",
    oprice: "NT$ 1,980",
    price: "NT$ 375",
  },
  {
    title: "Eco-Friendly Shopping Bag | CHAMOMILE - SIZE S",
    brand: "yaambrand",
    price: "NT$ 375",
  },
  {
    title: "Rex│銀色反光偏光鏡片│墨鏡│太陽眼鏡",
    brand: "墨鏡人生",
    oprice: "NT$ 1,980",
    price: "NT$ 375",
  },
];

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  mixins: [themeColor],
  data() {
    return {
      products,
      additions,
    };
  },
};
</script>

<style lang="scss" scoped>
.card {
  &-header {
    font-weight: 700;
    font-size: 17px;
  }
}

.products {
  &__item {
    color: #212121;
    text-decoration: none !important;
    display: grid;
    grid-template-columns: 35px 88px auto;
    grid-template-rows: min-content min-content max-content min-content;
    gap: 0px 1rem;
    grid-template-areas:
      "check photo title"
      "check photo price"
      "check photo quantity"
      "check promo promo";

    border-top: solid 1px #eee;

    &__check {
      grid-area: check;
      align-self: center;
    }

    &__photo {
      grid-area: photo;
    }
    &__title {
      grid-area: title;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
    }
    &__brand {
      grid-area: brand;
      font-size: 14px;
      font-weight: 400;
      color: #616161;
    }

    &__promo {
      grid-area: promo;
      font-size: 12px;
      font-weight: 500;
    }

    &__price {
      grid-area: price;
    }

    .b-form-spinbutton {
      border: none;
      text-align: center;
      width: auto;
      height: auto;
      padding: 0;

      ::v-deep .btn {
        background: #e7edf6;
        padding: 5px !important;
        border-radius: 8px !important;
      }

      ::v-deep output {
        text-align: center !important;
        padding: 0.5rem 1rem;
        border: none !important;
        font-weight: 500;
        font-size: 15px;
      }
    }
  }
}


.detail {
  font-size: 14px;

  border-top: solid 1px #eee;
  border-bottom: solid 1px #eee;
}

.sticky-bottom{
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  z-index: 1050;

  box-shadow: 0px -2px 4px 0px #0000000D;

}
</style>
